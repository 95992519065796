export default [
  {
    path: '/student-profile',
    name: 'student_profile',
    permission: 'access_student',
    component: () => import('@/views/for_student/profile/personal'),
    meta: { pageTitle: 'personal', breadcrumb: [{ text: 'personal', active: true }] },
  },

  {
    path: '/student-academic',
    name: 'student_academic',
    permission: 'access_student',
    component: () => import('@/views/for_student/profile/academic'),
    meta: { pageTitle: 'academic', breadcrumb: [{ text: 'academic', active: true }] },
  },
  {
    path: '/offering/registration',
    name: 'offering_registration',
    permission: 'access_student',
    component: () => import('@/views/for_student/offering/registration'),
    meta: { pageTitle: 'Course Registration', breadcrumb: [{ text: 'Course Registration', active: true }] },
  },
  {
    path: '/services',
    name: 'Other',
    permission: "access_services_requests",
    component: () =>import('@/views/for_student/service/services/index'),
    meta: { pageTitle: 'Services', breadcrumb: [{ text: 'Services'}] },
  },
  {
    path: '/services/add',
    name: 'new_service',
    permission: "access_services_requests",
    component: () =>import('@/views/for_student/service/services/add'),
    meta: { pageTitle: 'Services',navActiveLink: 'Other', breadcrumb: [{text : 'Services',to: '/services'},{text: 'Add',active: true}] },
  },
  {
    path: '/services/show/:id',
    name: 'other_show',
    permission: "access_services_requests",
    component: () =>import('@/views/for_student/service/services/show'),
    meta: { pageTitle: 'Services',navActiveLink: 'Other', breadcrumb: [{text : 'Services',to: '/services'},{text: 'show',active: true}] },
  },
  {
    path: '/services/certification',
    name: 'certification',
    permission: 'access_student',
    component: () => import('@/views/for_student/service/certification/index'),
    meta: { pageTitle: 'Certificates', breadcrumb: [{ text: 'Certificates' }] },
  },
  {
    path: '/certification/show/:id',
    name: 'show_certificate',
    permission: 'access_student',
    component: () => import('@/views/for_student/service/certification/show'),
    meta: { pageTitle: 'Certificates', breadcrumb: [{ text: 'Certificates' }] },
  },
  {
    path: '/services/certification/add',
    name: 'add_certification',
    permission: 'access_student',
    component: () => import('@/views/for_student/service/certification/add'),
    meta: { pageTitle: 'Certificates', breadcrumb: [{ text: 'Certificates', active: true }] },
  },
  {
    path: '/services/certification/edit/:id',
    name: 'edit_certification',
    permission: 'access_student',
    component: () => import('@/views/for_student/service/certification/add'),
    meta: { pageTitle: 'Certificates', breadcrumb: [{ text: 'Certificates', active: true }] },
  },
  {
    path: '/my-exam-schedules',
    name: 'my_exam_schedules',
    permission: 'access_student',
    component: () => import('@/views/for_student/my_exam_schedules/index'),
    meta: { pageTitle: 'My Exam Schedules', breadcrumb: [{ text: 'My Exam Schedules', active: true }] },
  },
  {
    path: '/myCourses',
    name: 'my_courses',
    permission: 'access_student',
    component: () => import('@/views/for_student/my_courses/index'),
    meta: { pageTitle: 'My Courses', breadcrumb: [{ text: 'My Courses', active: true }] },
  },
  {
    path: '/myCourses/:course_id',
    name: 'show_my_course',
    permission: 'access_student',
    component: () => import('@/views/for_student/my_courses/show'),
    meta: { pageTitle: 'Show Course', breadcrumb: [{ to: '/myCourses', text: 'My Courses' }, { text: 'Show Course', active: true }] },
  },
  {
    path: '/my_excuses',
    name: 'my_excuses',
    permission: 'access_student',
    component: () => import('@/views/for_student/my_excuses/index'),
    meta: { pageTitle: 'My Excuses', breadcrumb: [{ text: 'Excuses', active: true }] },
  },
  {
    path: '/my_excuses/add',
    name: 'add_excuse',
    permission: 'access_student',
    component: () => import('@/views/for_student/my_excuses/edit.vue'),
    meta: { pageTitle: 'Excuses', breadcrumb: [{ to: '/my_excuses', text: 'Excuses' }] },
  },
  {
    path: '/my_excuses/edit/:id',
    name: 'edit_excuse',
    permission: 'access_student',
    component: () => import('@/views/for_student/my_excuses/edit.vue'),
    meta: { pageTitle: 'Excuses', breadcrumb: [{ to: '/my_excuses', text: 'Excuses' }] },
  },

  {
    path: '/correction_requests',
    name: 'my_correction_requests',
    component: () => import('@/views/for_student/correction_requests/index'),
    meta: { pageTitle: 'My Correction Requests', breadcrumb: [{ text: 'My Correction Requests', active: true }] },
  },
  {
    path: '/correction_requests/add',
    name: 'add_correction_request',
    component: () => import('@/views/for_student/correction_requests/add'),
    meta: { pageTitle: 'My Excuses', breadcrumb: [{ to: '/correction_requests', text: 'My Correction Requests' }, { text: 'Request New Correction', active: true }] },
  },
  {
    path: '/schedule',
    name: 'my_schedule',
    component: () =>
      import('@/views/for_student/my_schedule/index'),
    meta: { pageTitle: 'My Schedule', breadcrumb: [{ text: 'Schedule', active: true }] },
  },
  {
    path: '/calendar',
    name: 'my_calendar',
    component: () =>
      import('@/views/for_student/my_calendar/index'),
    meta: { pageTitle: 'My Calendar', breadcrumb: [{ text: 'Calendar', active: true }] },
  },

  //My Thesis
  {
    path: '/my_thesis',
    name: 'my_thesis',
    // permission: 'access_theses',
    component: () => import('@/views/for_student/my_thesis/show'),
    meta: { pageTitle: 'my_thesis', breadcrumb: [{ text: 'my_thesis', active: true }] },
  },
  {
    path: '/my_theses/:id',
    name: 'show_thesis',
    // permission: 'access_theses',
    component: () => import('@/views/for_student/my_thesis/show'),
    meta: { pageTitle: 'Show Thesis', breadcrumb: [{ to: '/my_theses', text: 'my_theses' }, { text: 'Show Thesis', active: true }] },
  },
  {
    path: '/my_theses/add',
    name: 'add_thesis',
    // permission: 'add_thesis',
    component: () => import('@/views/for_student/my_thesis/edit'),
    meta: { pageTitle: 'my_theses', breadcrumb: [{ to: '/my_theses', text: 'my_theses' }] },
  },
  {
    path: '/my_theses/edit/:id?',
    name: 'edit_thesis',
    // permission: 'edit_thesis',
    component: () => import('@/views/for_student/my_thesis/edit'),
    meta: { pageTitle: 'my_theses', breadcrumb: [{ to: '/my_theses', text: 'my_theses' }] },
  },


  {
    path: '/my_credit',
    name: 'credit',
    // permission: 'access_student',
    component: () => import('@/views/for_student/my_credit/index'),
    meta: { pageTitle: 'credit', breadcrumb: [{ text: 'credit', active: true }] },
  },
  {
    path: '/my_credit/charge',
    name: 'charge_credit',
    // permission: 'access_student',
    component: () => import('@/views/for_student/my_credit/edit.vue'),
    meta: { pageTitle: 'credit', breadcrumb: [{ to: '/credit', text: 'credit' }] },
  }
];
